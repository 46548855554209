<template>
  <div
    v-if="show"
    class="covid-safety">
    <div class="health-insurance--icon" />
    <div>
      <div class="has-text-weight-bold">
        COVID-19 Safety Measures
      </div>
      <div>
        {{ covidSafety }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CovidSafetyInfo',
  props: {
    covidSafety: {
      type: String,
      default: null
    }
  },
  computed: {
    show () {
      return this.covidSafety !== null
    }
  }
}
</script>

<style lang="scss" scoped>
.covid-safety {
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  padding: 16px;

  & .health-insurance--icon {
    background-image: url('../assets/health-insurance.svg');
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    height: 50px;
    margin-right: 8px;
    width: 50px;
  }
}
</style>
