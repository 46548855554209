<template>
  <div>
    <div
      v-if="localGallery.length"
      class="gallery">
      <HotelImage
        v-if="hero"
        class="hero-img"
        :image="hero"
        is-loading
        is-descending
        @click.native="handleGalleryClick(0)">
        <i />
      </HotelImage>
      <div
        v-if="images"
        class="images">
        <HotelImage
          v-for="(image, index) in images"
          :key="`image-${index}`"
          class="img"
          :image="image"
          :is-loading="index < 6"
          @click.native="handleGalleryClick(index+1)">
          <span
            v-if="images.length === index+1">{{ $t('views.hotel.see-all-photos') }}</span>
          <i v-else />
        </HotelImage>
      </div>
    </div>
    <el-dialog
      :visible.sync="sliderActive"
      fullscreen
      custom-class="slider-dialog"
      @opened="handleSliderDialogOpened">
      <div class="slider-container">
        <!-- swiper1 -->
        <swiper
          ref="swiperTop"
          :options="swiperOptionTop"
          class="gallery-top">
          <HotelImage
            v-for="(item, index) in localGallery"
            :key="`slide-${index}`"
            class="slide-1"
            :custom-components="swiperSlideComponents"
            :image="item"
            is-loading
            is-descending />
          <div
            slot="button-next"
            class="swiper-button-next swiper-button-white" />
          <div
            slot="button-prev"
            class="swiper-button-prev swiper-button-white" />
        </swiper>
        <div class="slider-info">
          <span class="slider-count">{{ swiperIndex+1 }} / {{ gallery.length }}</span> <span class="slider-caption" />
        </div>
        <!-- swiper2 Thumbs -->
        <swiper
          ref="swiperThumbs"
          :options="swiperOptionThumbs"
          class="gallery-thumbs">
          <HotelImage
            v-for="(item, index) in localGallery"
            :key="`slide-thumbs-${index}`"
            class="slide-1"
            :custom-components="swiperSlideComponents"
            :image="item"
            is-loading />
        </swiper>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import HotelImage from '@/views/hotel/HotelImage.vue'

export default {
  name: 'HotelGallery',
  components: {
    swiper,
    HotelImage
  },
  props: {
    gallery: {
      type: Array,
      default () {
        return []
      },
      required: true
    }
  },
  data () {
    return {
      sliderActive: false,
      swiperOptionTop: {
        spaceBetween: 10
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 7,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperIndex: -1,
      swiperThumbs: null,
      localGallery: JSON.parse(JSON.stringify(this.gallery)),
      swiperSlideComponents: swiperSlide
    }
  },
  computed: {
    hero () {
      return this.localGallery.length ? this.localGallery[0] : null
    },
    images () {
      return this.localGallery.length > 1 ? this.localGallery.slice(1, 5) : null
    }
  },
  watch: {
    gallery (nv) {
      this.localGallery = JSON.parse(JSON.stringify(nv))
    }
  },
  mounted () {
    this.$Lazyload.$on('error', this.handleImageError)
  },
  beforeDestroy () {
    if (this.swiperThumbs) this.swiperThumbs.off('slideChange')
    this.$Lazyload.$off('error', this.handleImageError)
  },
  methods: {
    handleGalleryClick (i) {
      this.sliderActive = true
      this.swiperIndex = i
    },
    handleSliderDialogOpened () {
      if (!this.swiperThumbs) {
        console.log('test')
        const swiperTop = this.$refs.swiperTop.swiper
        const swiperThumbs = this.$refs.swiperThumbs.swiper
        this.swiperThumbs = swiperThumbs
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
        swiperThumbs.on('slideChange', () => {
          this.swiperIndex = swiperThumbs.realIndex
        })
      }
      this.swiperThumbs.slideToLoop(this.swiperIndex)
    },
    handleImageError ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error }) {
      this.removeImageFromLocalGallery(src)
      if (this.$refs.swiperTop) {
        this.$refs.swiperTop.swiper.update()
      }
      if (this.$refs.swiperThumbs) {
        this.$refs.swiperThumbs.update()
      }
    },
    removeImageFromLocalGallery (src) {
      console.log(this.localGallery)
      this.localGallery = this.localGallery.filter((item) => item.m.url !== src && item.s.url !== src)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

  .gallery {
    display: flex;
    flex-wrap: wrap;
  }

  .hero-img {
    flex: 1 1 50%;
    height: 400px;
    width: 50%;
    background-size: cover;
    background-position: center center;
    box-shadow: inset 0 0 0 1px $white;
    cursor: zoom-in;

    i {
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.50);
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      i {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.70);
      }
    }
  }

  .images {
    flex: 1 1 50%;
    height: 400px;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    cursor: zoom-in;
  }

  .img {
    flex: 1 1 50%;
    height: 200px;
    width: 50%;
    box-shadow: inset 0 0 0 1px $white;
    background-size: cover;
    background-position: center center;

    i {
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.50);
      transition: all 0.2s ease-in-out;
    }

    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.50);
      transition: all 0.2s ease-in-out;
      font-weight: bold;
      font-size: $size-6;
      color: $white;
    }

    &:hover {
      i {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.70);
      }

      span {
        background-color: rgba(0, 0, 0, 0.70);
      }
    }
  }

  .slider-container {
    max-width: 980px;
    height: calc(100vh - 100px);
    margin: 0 auto auto auto;
  }

  .swiper-slide {
    background-size: auto auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .gallery-top {
    height: calc(100% - 140px) !important;
    width: 100%;
  }

  .slider-info {
    height: 70px !important;
    color: $white;
    font-size: 1rem;
    font-weight: bold;
    padding-top: 12px;
  }

  .slider-count {
    margin-right: 60px;
  }

  .gallery-thumbs {
    height: 70px !important;
    box-sizing: border-box;
    padding: 0;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
</style>
<style lang="scss">
  @import "../../styles/bulma-variables";

  .slider-dialog {
    background-color: rgba(0, 0, 0, 0.9);

    .el-dialog__close {
      color: $white;
      font-size: 2rem;

      &:hover, &:focus, &:active {
        color: $white;
      }
    }
  }

</style>
