<template>
  <transition name="view-fade-slow">
    <div
      v-show="stickNavActive"
      class="sticky-nav">
      <a
        v-for="(it,i) in links"
        :key="i"
        class="sticky-nav-link"
        :class="{active:i===current}"
        @click.prevent="to(it)">{{ it.label }}</a>
    </div>
  </transition>
</template>

<script>
import smoothScroll from '../plugins/smoothScroll'
import throttle from 'lodash/throttle'

export default {
  name: 'StickyNav',
  props: {
    links: {
      type: Array,
      default () {
        return []
      }
    },
    stickyActiveOffset: { // When is sticky nav displayed
      type: Number,
      default: 65
    },
    scrollOffset: {
      type: Number,
      default: -48 // sticky nav height - border
    }
  },
  data () {
    return {
      current: -1,
      stickNavActive: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    to (link) {
      smoothScroll.scrollTo(link.hash, { offset: this.scrollOffset })
    },
    handleScroll: throttle(function () {
      const fromTop = window.scrollY
      if (fromTop > this.stickyActiveOffset) {
        this.stickNavActive = true
      } else {
        this.stickNavActive = false
      }
      let activeIndex = -1
      for (const [i, link] of Object.entries(this.links)) {
        const section = document.querySelector(link.hash)
        if (link.hash === '#app' || !section) continue

        const { top, bottom } = section.getBoundingClientRect()
        const offsetTop = top + this.scrollOffset
        const offsetBottom = bottom + this.scrollOffset

        const allContentInPreview = offsetTop >= 0 && offsetBottom <= window.innerHeight
        if (allContentInPreview) {
          activeIndex = Number(i)
          break
        }

        const show = offsetTop <= window.innerHeight / 2 && offsetBottom >= 0
        if (show) {
          activeIndex = Number(i)
        }
      }
      this.current = activeIndex
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
  @import "../styles/bulma-variables";

  .sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    display: flex;
    background-color: $white;
    box-shadow: inset 0px 3px 0 1px $white, inset 0px -1px 0 1px $color-9;
    z-index: $_jh_fullscreen-z-index - 1;
    padding: 0 30px;
  }

  .sticky-nav-link {
    display: block;
    box-sizing: border-box;
    padding: $bleed*2 $bleed*3;
    font-size: 1rem;
    font-weight: bold;
    border-bottom: 3px solid transparent;

    &.active {
      border-bottom: 3px solid $button-secondary-color;
    }
  }
</style>
