<template>
  <ul class="good-to-know">
    <li class="m-b-2x">
      <i
        class="m-r"
        :class="icon" /> <span
          class="has-text-weight-bold has-text-primary"
          v-html="text" />
    </li>
    <li
      v-for="(it,i) in highlightList"
      :key="i"
      class="m-b-2x no-icon has-text-grey-light">
      {{ it.text }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TrustYouGoodToKnow',
  props: {
    categoryId: String,
    shortText: String,
    text: String,
    sentiment: String,
    count: Number,
    relevance: Number,
    score: Number,
    highlightList: Array,
    highScore: {
      type: Number,
      default: 90
    }
  },
  computed: {
    icon () {
      return this.sentiment === 'pos' ? 'ap-icon-check has-text-success' : 'ap-icon-close has-text-danger'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .no-icon {
    padding-left: 20px;
  }

</style>
