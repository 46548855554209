<template>
  <g>
    <path d="M20.8813 7.55625L15.1938 1.86875C15.0313 1.70625 14.8687 1.625 14.625 1.625H6.5C5.60625 1.625 4.875 2.35625 4.875 3.25V22.75C4.875 23.6437 5.60625 24.375 6.5 24.375H19.5C20.3938 24.375 21.125 23.6437 21.125 22.75V8.125C21.125 7.88125 21.0438 7.71875 20.8813 7.55625ZM14.625 3.575L19.175 8.125H14.625V3.575ZM19.5 22.75H6.5V3.25H13V8.125C13 9.01875 13.7312 9.75 14.625 9.75H19.5V22.75Z" />
    <path d="M8.125 17.875H17.875V19.5H8.125V17.875Z" />
    <path d="M8.125 13H17.875V14.625H8.125V13Z" />
  </g>
</template>

<script>
export default {
  name: 'IconDoc'
}
</script>

<style scoped>

</style>
