<template>
  <li
    :class="['list-checkbox is-narrow', cssIsActive]"
    @click="handleClicked">
    <div class="list-action">
      <div class="list-checkbox--box">
        <i
          v-if="value"
          class="el-icon-check" />
      </div>
    </div>
    <div class="list-content list-checkbox--content">
      <slot />
    </div>
  </li>
</template>

<script>
export default {
  name: 'ListCheckBox',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    cssIsActive () {
      return this.value ? 'list-checkbox--active' : ''
    }
  },
  methods: {
    handleClicked () {
      this.$emit('input', !this.value)
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .list-checkbox {
    display: flex;
    align-items: center;
    color: #717171;
    line-height: 1.38;
    padding: 0.187em 0;
    cursor: pointer;
    &:not(.list-checkbox--disabled):hover {
      background-color: $white-bis;
      .list-checkbox--content {
        opacity: 1;
      }
      // box-shadow: $border-shadow;
      // z-index: 2;
    }
    &:not(.list-checkbox--disabled):active {
      background-color: $light;
    }
  }
  .list-checkbox--content {
    opacity: 0.68;
    .list-checkbox--active & {
      opacity: 1;
    }
  }
  .list-checkbox--box {
    width: 1.2em;
    height: 1.2em;
    border: 1px solid $list-border-color;
    border-radius: $bleed / 2;
  }
</style>
