<template>
  <ul>
    <li
      v-for="item in value"
      :key="item.key">
      <i class="el-icon-circle-check has-text-success" />{{ item.label }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PropertyFacilities',
  props: {
    value: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    margin-bottom: 25px;
    margin-top: 6px;

    li {
      font-size: 12px;
      position: relative;
      padding-left: 18px;

      i {
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }

</style>
