<template>
  <div>
    <el-button
      type="primary"
      class="btn-generate-quote button-secondary is-pulled-right"
      @click="generate">
      <IconBase class="icon-doc">
        <IconDoc />
      </IconBase>
      <p>Generate quote</p>
    </el-button>
  </div>
</template>

<script>
import IconDoc from '../../components/icons/iconDoc'

export default {
  name: 'QuoteButton',
  components: { IconDoc },
  props: {
    propertyCode: {
      type: String,
      default: ''
    }
  },
  methods: {
    generate () {
      const { query } = this.$route
      const quoteRoute = {
        name: 'quote',
        query: {
          ...query,
          propertyCodes: this.propertyCode,
          locationQuery: query.locationQuery,
          markupType: 'Percentage',
          discountType: 'Percentage'
        }
      }

      const routeData = this.$router.resolve(quoteRoute)
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-generate-quote {
    text-transform: none;

    ::v-deep span {
      display: flex;
      align-items: center;
    }

    .icon-doc {
      height: 22px;
      margin-right: 8px;
      margin-bottom: 0;
    }
  }
</style>
