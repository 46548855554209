<template>
  <div class="columns is-vcentered">
    <div class="column is-narrow has-text-weight-bold has-text-primary is-size-5">
      Filter rooms by:
    </div>
    <div class="column is-narrow list">
      <el-input
        v-model="roomName"
        size="small"
        placeholder="keyword"
        @input="handleChanged" />
    </div>
    <div class="column is-narrow list">
      <el-select
        v-model="mealFilter"
        size="small"
        placeholder="Board"
        @change="handleChanged">
        <el-option
          v-for="item in meals"
          :key="item.key"
          :label="item.label"
          :value="item.key" />
      </el-select>
    </div>
    <div class="column  is-narrow  list">
      <ListCheckBox
        v-model="refundable"
        @change="handleChanged">
        <span>{{ $t('components.FiltersRefundable.refundable') }}</span>
      </ListCheckBox>
    </div>
    <div class="column is-narrow list">
      <ListCheckBox
        v-model="nonRefundable"
        @change="handleChanged">
        <span>{{ $t('components.FiltersRefundable.nonrefundable') }}</span>
      </ListCheckBox>
    </div>
    <div
      v-if="payAtHotelEnabled"
      class="column is-narrow list">
      <ListCheckBox
        v-model="payAtHotel"
        @change="handleChanged">
        <span>{{ $t('filters.payAtHotel') }}</span>
      </ListCheckBox>
    </div>
    <div class="column is-narrow m-l-auto">
      <el-button
        type="primary"
        style="width: 120px;height: 48px;padding: 10px;"
        class="is-pulled-right button-secondary-outline"
        @click="handleClearFilter">
        {{ $t('views.search.clear') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ListCheckBox from '../../components/ListCheckBox'
import { meals } from '../../common'

const checkboxToQueryString = function (val) {
  if (!val) return undefined
  const actives = Object.entries(val).filter(([key, boo]) => boo)
  return actives.map(([key, boo]) => key).join(',')
}

export default {
  name: 'SearchBarOne',
  components: {
    ListCheckBox
  },
  data () {
    return {
      roomName: undefined,
      refundable: false,
      nonRefundable: false,
      payAtHotel: undefined,
      meals: Object.values(meals),
      mealFilter: undefined,
      delay: 800,
      timer: undefined
    }
  },
  computed: {
    ...mapGetters('app', [
      'payAtHotelEnabled'
    ]),
    refundFilter () {
      if ((this.refundable && this.nonRefundable) || (!this.refundable && !this.nonRefundable)) {
        return undefined
      } else if (this.refundable) {
        return 'true'
      } else {
        return 'false'
      }
    },
    payAtHotelFilter () {
      if (this.payAtHotel) {
        console.log(this.payAtHotel)
        return true
      } else {
        return undefined
      }
    }
  },
  created () {
    this.$emit('input', {
      refundFilter: this.refundFilter,
      payAtHotelFilter: this.payAtHotelFilter,
      meals: checkboxToQueryString(this.mealFilter)
    })
  },
  methods: {
    reset () {
      this.refundable = false
      this.nonRefundable = false
      this.payAtHotel = false
      this.mealFilter = {
        m1: false,
        m2: false
      }
      this.mealFilter = undefined
      this.roomName = undefined
    },
    handleClearFilter () {
      this.reset()
      this.$emit('input', {
        refundFilter: this.refundFilter,
        payAtHotelFilter: this.payAtHotelFilter,
        meals: undefined,
        roomName: undefined
      })
    },
    handleChanged () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('input', {
          refundFilter: this.refundFilter,
          payAtHotelFilter: this.payAtHotelFilter,
          meals: this.mealFilter,
          roomName: this.roomName
        })
      }, this.delay)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/bulma-variables";

  .button-secondary-outline {
    border: 2.5px solid #00A0D8;
    box-shadow: none !important;
    text-align: center !important;
    vertical-align: middle !important;
  }

  .list-action {
    padding-right: 10px;
  }
  .list-checkbox {
    display: flex;
    align-items: center;
    color: #000;
    line-height: 1.38;
    padding: 0.187em 0;
    cursor: pointer;
    &:not(.list-checkbox--disabled):hover {
      background-color: $white-bis;
      .list-checkbox--content {
        opacity: 1;
      }
      // box-shadow: $border-shadow;
      // z-index: 2;
    }
    &:not(.list-checkbox--disabled):active {
      background-color: $light;
    }
  }
  .list-checkbox--content {
    opacity: 0.68;
    .list-checkbox--active & {
      opacity: 1;
    }
  }
  .list-checkbox--box {
    width: 1.2em;
    height: 1.2em;
    border: 1px solid $list-border-color;
    border-radius: $bleed / 2;
  }
</style>
