<template>
  <div class="hotel-detail-page">
    <StickyNav :links="stickyNavLinks" />
    <HotelGallery :gallery="gallery" />
    <div class="section is-vertically-small section-breadcrumb">
      <div class="container">
        <!--breadcrumb start -->
        <div class="columns m-b-8x">
          <div class="column is-mobile">
            <PpBreadcrumb
              :routes="breadCrumb"
              class="has-text-weight-bold search-one--breadcrumb is-inline" />
            <span class="has-text-secondary has-text-weight-bold">{{ property.name }}</span>
          </div>
          <div class="column is-mobile is-narrow">
            <router-link
              :to="toAllProperties"
              class="has-text-secondary has-text-weight-bold">
              <IconBase
                width="13"
                height="13"
                icon-name="arrow left">
                <IconArrowLeft />
              </IconBase>
              {{ $t('views.hotel.See all properties') }}
            </router-link>
          </div>
        </div>
        <!--breadcrumb end -->
        <!--summary start -->
        <div class="section-summary columns is-mobile">
          <div class="column">
            <StarRatings :ratings="property.starRating" />
            <h1 class="has-text-primary is-size-3 has-text-weight-bold has-line-height-1-125 m-b">
              {{ property.name }}
              <Bookmark
                v-if="!isLoading"
                :status="property.isBookmarked"
                @toggled="handleBookmark" />
            </h1>
            <PropertyAddress
              :params="$route.params"
              :query="$route.query"
              :map-link="true"
              :location="property && property.location"
              class="has-text-base" />
            <span
              v-if="reviews && reviewsSummary && reviewsSummary.location">~ {{ reviewsSummary && reviewsSummary.location.text }}</span>
            <p class="m-b-4x">
              <router-link
                v-if="availability.search"
                :to="{name:'feedback',query:{type:'wrongInformation',hotelId:availability.search.hotel_id||'' }}"

                class="has-text-secondary has-text-underline">
                {{ $t('views.hotel.Wrong hotel info') }}
              </router-link>
            </p>
            <div v-if="Object.keys(reviewsSummary).length">
              <BadgeOutline
                :no-margin="true"
                color="yellow"
                class="m-r-2x">
                <TrustYouRecommendation
                  :reviews-distribution="reviewsSummary && reviewsSummary.reviewsDistribution" />
              </BadgeOutline>
              <TrustYouPopularWith
                :no-margin="true"
                v-bind="reviewsSummary && reviewsSummary.popularWith" />
            </div>
          </div>
          <div class="column is-narrow">
            <TrustYouRating
              :condensed="true"
              v-bind="propertyTrustYou"
              size="s" />
            <div class="is-clearfix m-b-4x" />
            <TrustYouBadgeWrapper
              v-if="Object.keys(reviewsSummary).length"
              :badge-list="reviews.badgeList"
              class="is-pulled-right">
              <template slot-scope="{ ranking }">
                <TrustYouRanked
                  v-bind="ranking.badgeData"
                  class="is-size-7-mobile"
                  layout="vertical" />
              </template>
            </TrustYouBadgeWrapper>
          </div>
        </div>
        <!--summary end -->

        <CovidSafetyInfo :covid-safety="property.covidSafety" />

        <!--rooms start -->
        <div
          id="rooms"
          class="section-rooms">
          <hr class="m-b-2x">
          <header class="columns is-vcentered m-b-4x">
            <div class="column is-narrow">
              <h2 class="has-text-primary has-text-weight-bold is-size-4">
                {{ $t('views.hotel.Rooms') }}
              </h2>
            </div>
            <div class="column">
              <QuoteButton :property-code="property.propertyCode" />
            </div>
          </header>
          <SearchBarOne
            id="pckgslist"
            class="m-b-4x"
            @search="handleSearch" />
          <div class="columns">
            <RoomFilters
              class="column is-10 m-b-4x p-r-0"
              @input="handleSearch" />
          </div>

          <PackagesList
            v-if="availability.search || isLoading"
            :search-id="query.searchId"
            :property-code="query.propertyCode"
            :is-loading="isLoading"
            :selected-pckg-idx="selectedPckgIdx"
            :packages="availability.packages"
            :total-items="totalItems"
            :is-searching-more="isSearchingMore"
            @more="handleMore"
            @submit-request="handleSendRequest">
            <Packages
              slot-scope="{ pckg, idx}"
              :nights="nights"
              :pckg="pckg"
              :adult-count="adultCount"
              :children-count="childrenCount"
              :room-count="roomCount">
              <el-button
                slot="call-to-action"
                class="button-secondary"
                style="width: 130px;"
                @click="handleSelectedPackage(pckg, idx, true)">
                {{ $t('views.SearchOne.select') }}
              </el-button>
              <el-button
                slot="call-to-action-noRefundAble"
                class="button-secondary"
                style="width: 130px;"
                @click="handleSelectedPackage(pckg, idx, false)">
                {{ $t('views.SearchOne.select') }}
              </el-button>
            </Packages>
          </PackagesList>
          <EmptyState
            v-else
            :is-loading="false"
            :message="$t('views.SearchOne.empty-state')"
            class="has-text-centered" />
        </div>
        <!--rooms end -->
        <!--Hotel Info start -->
        <div
          id="hotel-info"
          class="section-hotel-info">
          <hr class="m-b-2x">
          <h2
            class="has-text-primary has-text-weight-bold is-size-4 m-b-4x">
            {{ $t('views.hotel.Hotel Info') }}
          </h2>
          <div class="columns">
            <div class="column is-3">
              <div v-if="propertyFacilities && propertyFacilities.public && propertyFacilities.public.length">
                <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
                  {{ $t('views.SearchOne.Hotel Facilities') }}
                </h4>
                <PropertyFacilities :value="propertyFacilities.public" />
              </div>
              <div v-if="propertyFacilities && propertyFacilities.private && propertyFacilities.private.length">
                <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
                  {{ $t('views.SearchOne.Room Facilities') }}
                </h4>
                <PropertyFacilities :value="propertyFacilities.private" />
              </div>
            </div>
            <div class="column is-9">
              <div
                class="content"
                v-html="property.description" />
            </div>
          </div>
        </div>
        <!--Hotel Info end -->
        <!--Policies start -->
        <div
          v-if="property.policy"
          id="policies"
          class="section-policies">
          <hr class="m-b-2x">
          <h2
            class="has-text-primary has-text-weight-bold is-size-4 m-b-4x">
            {{ $t('views.SearchOne.property-policies') }}
          </h2>
          <div class="columns">
            <div class="column is-3">
              <ul>
                <li
                  v-if="property.checkInTime"
                  class="m-b-2x">
                  <h4
                    class="is-size-5 has-text-primary has-text-weight-bold">
                    {{ $t('views.SearchOne.check-in-time') }}
                  </h4>
                  <div>{{ $t('views.hotel.Check in starts at') }} {{ property.checkInTime|checkInOutTime }}</div>
                </li>
                <li
                  v-if="property.checkOutTime"
                  class="m-b-2x">
                  <h4
                    class="is-size-5 has-text-primary has-text-weight-bold">
                    {{ $t('views.SearchOne.check-out-time') }}
                  </h4>
                  <div>{{ $t('views.hotel.Check out starts at') }} {{ property.checkOutTime|checkInOutTime }}</div>
                </li>
                <li
                  v-if="property.airportCode"
                  class="m-b-2x">
                  <h4
                    class="is-size-5 has-text-primary has-text-weight-bold">
                    {{ $t('views.SearchOne.nearest-airport') }}
                  </h4>
                  <div>{{ property.airportCode || '–' }}</div>
                </li>
                <!--                <li class="m-b-2x">-->
                <!--                  <h4-->
                <!--                    class="is-size-5 has-text-primary has-text-weight-bold">{{ $t('views.hotel.Others') }}</h4>-->
                <!--                  <div>{{ $t('views.hotel.No rollaway extra beds available') }}</div>-->
                <!--                </li>-->
              </ul>
            </div>
            <div class="column is-9">
              <div
                class="content"
                v-html="property.policy" />
            </div>
          </div>
        </div>
        <!--Policies end -->
        <!--Reviews start -->
        <div
          v-if="reviews && Object.keys(reviewsSummary).length"
          id="reviews"
          class="section-reviews">
          <hr class="m-b-2x">
          <div class="columns is-mobile is-gapless">
            <div class="column">
              <h2
                class="has-text-secondary has-text-weight-bold is-size-4 m-b-3x">
                {{ $t('views.hotel.Reviews') }}
              </h2>
              <h4
                class="has-text-primary has-text-weight-bold is-size-5">
                {{ $t('views.hotel.Summary of reviews',{'count':propertyTrustYou.reviewsCount}) }}
              </h4>
            </div>
            <div class="column is-narrow">
              <img
                width="124"
                height="42"
                src="../../assets/trustyou.png">
            </div>
          </div>
          <div class="columns">
            <div class="column is-3">
              <TrustYouRating
                :condensed="true"
                v-bind="propertyTrustYou"
                size="s"
                class="m-b-2x" />
              <TrustYouBadgeList :badge-list="reviews.badgeList" />
            </div>
            <div class="column is-9">
              <div
                class="has-text-secondary is-size-5 has-text-weight-bold m-b">
                {{ reviewsSummary.text }}
              </div>
              <div class="m-b-5x has-text-grey-light">
                <span
                  v-for="(it, idx) in reviewsSummary.summarySentenceList"
                  :key="idx"
                  class="m-r">
                  "{{ it.text }}"
                </span>
              </div>
              <div>
                <TrustYouHotelType
                  v-for="it in reviews.hotelTypeList"
                  :key="it.categoryId"
                  v-bind="it"
                  text-type="shortText" />
              </div>
            </div>
          </div>
          <div v-if="reviews.goodToKnowList">
            <h4
              class="has-text-primary has-text-weight-bold is-size-5 m-b-5x">
              {{ $t('views.hotel.Good to know') }}
            </h4>
            <div class="columns is-multiline">
              <div
                v-for="(it, idx) in reviews.goodToKnowList"
                :key="idx"
                class="column is-3">
                <TrustYouGoodToKnow v-bind="it" />
              </div>
            </div>
          </div>
          <div v-if="reviews.categoryList">
            <h4
              class="has-text-primary has-text-weight-bold is-size-5 m-b-5x">
              {{ $t('views.hotel.Review highlight') }}
            </h4>
            <TrustYouHighLight
              v-for="it in reviews.categoryList"
              :key="it.categoryId"
              :category="it" />
          </div>
        </div>
        <!--Reviews end -->
      </div>
    </div>
    <BookingPolicyPopup
      v-if="bookingPolicyModalVisibility"
      :nights="nights"
      :property="property"
      :selected-pckg="selectedPckg"
      :search-id="query.searchId"
      :refund-able="selectedRefundAble"
      :currency="query.currency"
      :search-object="availability.search"
      :location-query="query.locationQuery"
      :disable-reserve-btn="isReserving"
      @close="toggleBookingPolicyModal(false)"
      @fetchHotelPackages="onFetchHotelPackages"
      @next="handleReserveNow"
      @submit-request="handleSendRequest('bookingPolicyPopup')" />
    <PpModal v-model="searchPageTimeOut">
      <PpCard class="box has-border is-flex">
        <div class="has-text-centered time-out-modal-content">
          <div class="m-b-3x">
            <IconBase
              width="44"
              height="70">
              <IconTimeout />
            </IconBase>
          </div>
          <div class="m-b-8x">
            {{ $t('components.BookingPolicyPopup.timeout') }}
          </div>
          <div>
            <el-button
              class="button-secondary"
              style="min-width: 155px;"
              @click="searchPageTimeOut=false">
              {{ $t('views.hotel.Refresh') }}
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import smoothScroll from '@/plugins/smoothScroll'
import HotelGallery from './HotelGallery'
import IconArrowLeft from '@/components/icons/IconArrowLeft'
import TrustYouRating from '@/components/TrustYou/TrustYouRating'
import StarRatings from '@/components/StarRatings'
import Bookmark from '@/components/Bookmark'
import PropertyAddress from '@/components/PropertyAddress'
import TrustYouBadgeWrapper from '@/components/TrustYouBadgeWrapper'
import TrustYouRanked from '@/components/TrustYouRanked'
import BadgeOutline from '@/components/BadgeOutline'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import SearchBarOne from './SearchBarOne'
import RoomFilters from './RoomFilters'
import Packages from '@/components/Packages'
import PackagesList from '@/components/PackagesList'
import TrustYouBadgeList from '@/components/TrustYouBadgeList'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouGoodToKnow from '@/components/TrustYouGoodToKnow'
import TrustYouHighLight from '@/components/TrustYouHighLight'
import StickyNav from '@/components/StickyNav'
import BookingPolicyPopup from '@/components/BookingPolicyPopup'
import IconTimeout from '@/components/icons/IconTimeout'
import PropertyFacilities from '@/components/PropertyFacilities'
import QuoteButton from './QuoteButton'
import CovidSafetyInfo from '@/components/CovidSafetyInfo'

export default {
  name: 'HotelDetail',
  components: {
    PropertyFacilities,
    IconTimeout,
    BookingPolicyPopup,
    StickyNav,
    TrustYouHighLight,
    TrustYouGoodToKnow,
    TrustYouHotelType,
    TrustYouBadgeList,
    PackagesList,
    Packages,
    SearchBarOne,
    TrustYouRecommendation,
    TrustYouPopularWith,
    BadgeOutline,
    TrustYouRanked,
    TrustYouBadgeWrapper,
    PropertyAddress,
    Bookmark,
    StarRatings,
    TrustYouRating,
    IconArrowLeft,
    HotelGallery,
    RoomFilters,
    QuoteButton,
    CovidSafetyInfo
  },
  props: {
    params: Object,
    query: Object
  },
  data () {
    return {
      selectedPckgIdx: 0,
      selectedPckg: {},
      selectedRefundAble: false,
      bookingPolicyModalVisibility: false,
      searchPageTimeOut: false,
      isReserving: false
    }
  },
  computed: {
    ...mapState('searchOne', [
      // 'property',
      'availability',
      // 'reviews',
      'isSearching',
      'isSearchingMore',
      'fetchCompleted',
      'nights'
    ]),
    ...mapState('bookingPolicy', [
      'nextStep',
      'bookingPolicy'
    ]),
    stickyNavLinks () {
      let navLinks = [
        {
          label: this.$t('views.hotel.Back to top'),
          hash: '#app'
        },
        {
          label: this.$t('views.hotel.Rooms'),
          hash: '#rooms'
        },
        {
          label: this.$t('views.hotel.Hotel Info'),
          hash: '#hotel-info'
        }
      ]
      if (this.property && this.property.policy) {
        navLinks.push({
          label: this.$t('views.hotel.Policies'),
          hash: '#policies'
        })
      }
      if (this.reviews && this.reviewsSummary) {
        navLinks.push({
          label: this.$t('views.hotel.Reviews'),
          hash: '#reviews'
        })
      }
      return navLinks
    },
    property () {
      return this.$store.state.searchOne.property || { name: this.params.name }
    },
    reviews () {
      return this.$store.state.searchOne.reviews
    },
    isLoading () {
      return this.isSearching || !this.fetchCompleted
    },
    history () {
      return window.history
    },
    toAllProperties () {
      const { propertyCode, locationQuery, ...query } = this.query
      return {
        name: 'search',
        params: { locationQuery: this.query.locationQuery },
        query
      }
    },
    totalItems () {
      return this.availability.pagination ? this.availability.pagination.totalItems : 0
    },
    breadCrumb () {
      return [
        {
          label: this.$t('views.breadcrumb.Find Hotel'),
          replace: true,
          historyBack: false, // historyBack has a bug, will cause the link to fail. disabled it.
          route: { name: 'homepage' }
        },
        {
          label: this.query.locationQuery,
          replace: true,
          historyBack: false, // historyBack has a bug, will cause the link to fail. disabled it.
          route: {
            name: 'search',
            params: { locationQuery: this.query.locationQuery },
            query: this.query
          }
        }
      ]
      // return [
      //   {
      //     label: this.query.locationQuery,
      //     replace: true,
      //     historyBack: true,
      //     route: { name: 'searchMany', params: { locationQuery: this.query.locationQuery }, query: this.query }
      //   }
      // ]
    },
    adultCount () {
      return this.availability.search ? this.availability.search.adult_count : 0
    },
    childrenCount () {
      return this.availability.search && this.availability.search.children ? this.availability.search.children.length : 0
    },
    roomCount () {
      return this.availability.search ? this.availability.search.room_count : 0
    },
    children () {
      return this.availability.search && this.availability.search.children ? this.availability.search.children : []
    },
    reviewsSummary () {
      try {
        return this.reviews.summary || {}
      } catch {
        return {}
      }
    },
    propertyFacilities () {
      try {
        return this.property.facilities || {}
      } catch {
        return {}
      }
    },
    propertyTrustYou () {
      try {
        return this.property.trustYou || {}
      } catch {
        return {}
      }
    },
    hero () {
      try {
        const wrongImageUrlRegex = /\/\?wl_app_code|\/undefined\?wl_app_code|\/$|\/undefined/gi
        const heroImage = this.property.heroImage
        const isHeroImageValid = !wrongImageUrlRegex.test(heroImage.url)
        if (isHeroImageValid) {
          return heroImage
        }
        throw new Error('Not valid hero image')
      } catch {
        return null
      }
    },
    propertyGallery () {
      return this.property.gallery || []
    },
    gallery () {
      if (this.hero && this.propertyGallery.length) {
        const newGallery = [...this.propertyGallery]
        const isHeroSameWithTheFirstImage = Object.keys(newGallery[0]).find(key => newGallery[0][key].url === this.hero.url)
        if (!isHeroSameWithTheFirstImage) {
          newGallery.unshift({ s: this.hero, m: this.hero, l: this.hero })
        }
        return newGallery
      }
      return this.propertyGallery
    }
  },
  created () {
    this.onFetchHotelPackages(true)
  },
  methods: {
    onFetchHotelPackages (isSearchDetails = false) {
      if (!this.query.searchId) {
        this.query.searchId = this.$newSearchId()
      }
      isSearchDetails && this.$store.dispatch('searchOne/details', this.query)
      this.$store.dispatch('searchOne/search', { ...this.query, page: 1, limit: 6 })
      this.$store.commit('searchMany/SET_SEARCH_MANY', {
        selected: this.query.propertyCode
      })
    },
    handleBookmark (val) {
      this.$store.dispatch('user/toggleBookmark', { flag: val, property: this.property })
    },
    toggleBookingPolicyModal (value) {
      this.bookingPolicyModalVisibility = value
      if (!value) {
        this.selectedPckg = {}
        // when close booking policy modal ,just update search id for searching
        // this.handleSearch({ searchId: this.$newSearchId() })
      }
      this.isReserving = false // in case user use browser back button
    },
    handleSearch (val) {
      const newQuery = { ...this.query, ...this.queryParams, ...val }
      const { regionName, ...query } = newQuery
      this.$store.commit('searchMany/SET_QUERY_PARAMS', newQuery)
      this.$router.replace({
        name: 'property',
        params: {
          name: this.property.name
        },
        query
      }, () => {
        this.$store.commit(
          'searchOne/SET_SEARCH_ONE',
          {
            fetchCompleted: false,
            availability: { results: [] }
          }
        )
        this.$store.dispatch('searchOne/search', query)
        smoothScroll.scrollTo('#pckgslist')
      })
    },
    async handleSelectedPackage (pckg, idx, refundAble) {
      this.selectedPckgIdx = idx
      this.selectedPckg = pckg
      this.selectedRefundAble = refundAble
      setTimeout(() => {
        this.toggleBookingPolicyModal(true)
      }, 380)
    },
    handleReserveNow (nextStep) {
      if (this.isReserving) return
      this.isReserving = true

      // router finished callback
      const onRouterJumpDone = () => {
        this.bookingPolicyModalVisibility = false
        this.isReserving = false
      }

      this.$router.push(
        {
          name: 'book',
          params: { ckToken: nextStep.ckToken },
          query: { currency: this.query.currency, payType: nextStep.payType, paymentMethod: nextStep.paymentMethod, sourceMarket: this.query.nationality || undefined }
        },
        onRouterJumpDone,
        onRouterJumpDone
      )
    },
    handleMore () {
      let { page } = JSON.parse(JSON.stringify(this.availability.pagination))
      this.$store.dispatch('searchOne/searchMore', { ...this.query, page: ++page, limit: 6 })
    },
    handleSendRequest (caller) {
      const { search } = this.$store.state.searchOne.availability
      const property = this.$store.state.searchOne.property
      const payload = {
        locationQuery: this.query.locationQuery,
        propertyName: property.name,
        checkInDate: search.check_in_date,
        checkOutDate: search.check_out_date,
        nationality: search.source_market,
        currency: this.query.currency,
        adultCount: this.adultCount,
        childrenCount: this.childrenCount,
        roomCount: search.room_count,
        children: this.children
      }
      this.$store.dispatch('bookingPolicy/sendNoAvailabilityInfo', payload)
        .then(() => {
          if (caller === 'bookingPolicyPopup') {
            this.bookingPolicyModalVisibility = false
          }
          this.$alert('Request has been sent', 'Success', {
            confirmButtonClass: 'button-secondary button-100',
            confirmButtonText: this.$t('app.ok'),
            customClass: 'success-message-box'
          })
        })
    }
  },
  metaInfo () {
    const title = `${this.property.name}, ${this.query.locationQuery}`
    return {
      title: title
    }
  }
}
</script>

<style scoped>
.time-out-modal-content {
  max-width: 500px;
  padding: 30px 50px;
}

.search-one--breadcrumb ::v-deep svg{
  pointer-events: none;
}

.generate-quote {
  flex-direction: row-reverse;
  padding: 0 12px;
}
</style>
