<template>
  <header class="search-bar-one">
    <form
      class="columns  is-multiline"
      @submit.prevent="handleSearch">
      <div class="column">
        <DatePicker
          v-model="dateRangeModel"
          style="min-width: 140px;" />
        <div
          v-show="dateRangeError"
          class="is-size-7 has-text-danger">
          {{ dateRangeError }}
        </div>
      </div>
      <div class="column">
        <SearchGuest
          v-model="guestModel"
          @opened="preventSearch"
          @closed="enableSearch" />
      </div>
      <div class="column is-narrow">
        <InputCurrency v-model="currency" />
      </div>
      <div class="column is-narrow">
        <el-button
          type="primary"
          class="is-pulled-right"
          style="min-width: 120px;"
          :disabled="isSearchDisabled"
          native-type="submit">
          {{ $t('components.SearchBarOne.search') }}
        </el-button>
      </div>
    </form>
  </header>
</template>

<script>
import SearchGuest from '../../components/SearchGuest'
import DatePicker from '../../components/DatePicker/index'
import InputCurrency from '../../components/InputCurrency'

export default {
  name: 'SearchBarOne',
  components: {
    InputCurrency,
    [SearchGuest.name]: SearchGuest,
    DatePicker
  },
  async beforeRouteUpdate (to, from, next) {
    next()
  },
  data () {
    return {
      dateRange: [],
      dateRangeModel: {
        checkInDate: this.$route.query.checkInDate,
        checkOutDate: this.$route.query.checkOutDate
      },
      guestModel: {
        adultCount: this.$route.query.adultCount || 1,
        roomCount: this.$route.query.roomCount || 1,
        children: this.$route.query.children
      },
      currency: this.$route.query.currency,
      meta: {
        placeId: undefined,
        queryString: '',
        starRatingPopover: false,
        pricesPopover: false
      },
      locationError: '',
      dateRangeError: '',
      isSearchDisabled: false
    }
  },
  computed: {
    searchManyQuery () {
      return {
        checkInDate: this.dateRangeModel.checkInDate,
        checkOutDate: this.dateRangeModel.checkOutDate,
        roomCount: this.guestModel.roomCount,
        adultCount: this.guestModel.adultCount,
        children: this.guestModel.children || undefined
      }
    }
  },
  watch: {
    'dateRangeModel': {
      deep: true,
      handler (nV) {
        this.dateRangeValidator(nV)
      }
    }
  },
  methods: {
    dateRangeValidator (val) {
      if (val == null) {
        this.dateRangeError = this.$t('components.SearchBarOne.check-in-required')
        return false
      }
      if (val.checkOutDate == null || val.checkOutDate === '') {
        this.dateRangeError = this.$t('components.SearchBarOne.check-out-required')
        return false
      }
      this.dateRangeError = ''
      return true
    },
    getQuery (more) {
      return {
        ...(this.searchManyQuery),
        currency: this.currency,
        searchId: this.$newSearchId(),
        ...more
      }
    },
    handleSearch () {
      const query = this.getQuery()
      if (!this.dateRangeValidator({
        checkInDate: query.checkInDate,
        checkOutDate: query.checkOutDate
      })) return

      this.$emit('search', query)
    },
    preventSearch () {
      this.isSearchDisabled = true
    },
    enableSearch () {
      this.isSearchDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
</style>
