<template>
  <div>
    <slot
      :badges="badges"
      :ranking="ranking" />
  </div>
</template>

<script>
export default {
  name: 'TrustYouBadgeWrapper',
  props: {
    badgeList: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    threshold: {
      type: Number,
      default: 69
    },
    limit: {
      type: Number,
      default: 3
    }
  },
  computed: {
    localBadges () {
      return Array.isArray(this.badgeList) && this.badgeList.length > 0 ? this.badgeList : null
    },
    badges () {
      if (this.localBadges == null) return []
      return this.localBadges.filter(({ badgeData }) => badgeData.score > this.threshold).slice(0, this.limit) || []
    },
    ranking () {
      if (this.localBadges == null) return []
      return this.localBadges.find(({ badgeType }) => badgeType === 'ranking') || {}
    }
  }
}
</script>
