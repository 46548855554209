<template>
  <div class="hotel-detail-page">
    <HotelGallery :gallery="property.gallery" />
    <div class="section is-vertically-small section-breadcrumb">
      <div class="container">
        <!--breadcrumb start -->
        <div class="columns m-b-8x">
          <div class="column is-mobile">
            <span class="has-text-secondary has-text-weight-bold">{{ property.name }}</span>
          </div>
        </div>
        <!--breadcrumb end -->
        <!--summary start -->
        <div class="section-summary columns is-mobile">
          <div class="column">
            <StarRatings :ratings="property.starRating" />
            <h1 class="has-text-primary is-size-3 has-text-weight-bold has-line-height-1-125 m-b">
              {{ property.name }}
              <Bookmark
                v-if="!isLoading"
                :status="property.isBookmarked"
                @toggled="handleBookmark" />
            </h1>
            <PropertyAddress
              :params="$route.params"
              :query="$route.query"
              :map-link="true"
              :location="property && property.location"
              class="has-text-base" />
            <span
              v-if="reviews && reviews.summary && reviews.summary.location">~ {{ reviews.summary && reviews.summary.location.text }}</span>
            <p class="m-b-4x">
              <router-link
                v-if="availability.search"
                :to="{name:'feedback',query:{type:'wrongInformation',hotelId:availability.search.hotel_id||'' }}"

                class="has-text-secondary has-text-underline">
                {{ $t('views.hotel.Wrong hotel info') }}
              </router-link>
            </p>
            <div v-if="reviews.summary">
              <BadgeOutline
                :no-margin="true"
                color="yellow"
                class="m-r-2x">
                <TrustYouRecommendation
                  :reviews-distribution="reviews.summary && reviews.summary.reviewsDistribution" />
              </BadgeOutline>
              <TrustYouPopularWith
                :no-margin="true"
                v-bind="reviews.summary && reviews.summary.popularWith" />
            </div>
          </div>
          <div class="column is-narrow">
            <TrustYouRating
              :condensed="true"
              v-bind="property.trustYou"
              size="s" />
            <div class="is-clearfix m-b-4x" />
            <TrustYouBadgeWrapper
              v-if="reviews.summary"
              :badge-list="reviews.badgeList"
              class="is-pulled-right">
              <template slot-scope="{ ranking }">
                <TrustYouRanked
                  v-bind="ranking.badgeData"
                  class="is-size-7-mobile"
                  layout="vertical" />
              </template>
            </TrustYouBadgeWrapper>
          </div>
        </div>
        <!--summary end -->

        <CovidSafetyInfo :covid-safety="property.covidSafety" />

        <!--Hotel Info start -->
        <div
          id="hotel-info"
          class="section-hotel-info">
          <hr class="m-b-2x">
          <h2
            class="has-text-primary has-text-weight-bold is-size-4 m-b-4x">
            {{ $t('views.hotel.Hotel Info') }}
          </h2>
          <div class="columns">
            <div class="column is-3">
              <div v-if="property.facilities && property.facilities.public && property.facilities.public.length">
                <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
                  {{ $t('views.SearchOne.Hotel Facilities') }}
                </h4>
                <PropertyFacilities :value="property.facilities.public" />
              </div>
              <div v-if="property.facilities && property.facilities.private && property.facilities.private.length">
                <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
                  {{ $t('views.SearchOne.Room Facilities') }}
                </h4>
                <PropertyFacilities :value="property.facilities.private" />
              </div>
            </div>
            <div class="column is-9">
              <div
                class="content"
                v-html="property.description" />
            </div>
          </div>
        </div>
        <!--Hotel Info end -->
        <!--Policies start -->
        <div
          v-if="property.policy"
          id="policies"
          class="section-policies">
          <hr class="m-b-2x">
          <h2
            class="has-text-primary has-text-weight-bold is-size-4 m-b-4x">
            {{ $t('views.SearchOne.property-policies') }}
          </h2>
          <div class="columns">
            <div class="column is-3">
              <ul>
                <li
                  v-if="property.checkInTime"
                  class="m-b-2x">
                  <h4
                    class="is-size-5 has-text-primary has-text-weight-bold">
                    {{ $t('views.SearchOne.check-in-time') }}
                  </h4>
                  <div>{{ $t('views.hotel.Check in starts at') }} {{ property.checkInTime|checkInOutTime }}</div>
                </li>
                <li
                  v-if="property.checkOutTime"
                  class="m-b-2x">
                  <h4
                    class="is-size-5 has-text-primary has-text-weight-bold">
                    {{ $t('views.SearchOne.check-out-time') }}
                  </h4>
                  <div>{{ $t('views.hotel.Check out starts at') }} {{ property.checkOutTime|checkInOutTime }}</div>
                </li>
                <li
                  v-if="property.airportCode"
                  class="m-b-2x">
                  <h4
                    class="is-size-5 has-text-primary has-text-weight-bold">
                    {{ $t('views.SearchOne.nearest-airport') }}
                  </h4>
                  <div>{{ property.airportCode || '–' }}</div>
                </li>
              </ul>
            </div>
            <div class="column is-9">
              <div
                class="content"
                v-html="property.policy" />
            </div>
          </div>
        </div>
        <!--Policies end -->
        <!--Reviews start -->
        <div
          v-if="reviews.summary"
          id="reviews"
          class="section-reviews">
          <hr class="m-b-2x">
          <div class="columns is-mobile is-gapless">
            <div class="column">
              <h2
                class="has-text-secondary has-text-weight-bold is-size-4 m-b-3x">
                {{ $t('views.hotel.Reviews') }}
              </h2>
              <h4
                class="has-text-primary has-text-weight-bold is-size-5">
                {{ $t('views.hotel.Summary of reviews',{'count':property.trustYou ? property.trustYou.reviewsCount : 0}) }}
              </h4>
            </div>
            <div class="column is-narrow">
              <img
                width="124"
                height="42"
                src="../../assets/trustyou.png">
            </div>
          </div>
          <div class="columns">
            <div class="column is-3">
              <TrustYouRating
                :condensed="true"
                v-bind="property.trustYou"
                size="s"
                class="m-b-2x" />
              <TrustYouBadgeList :badge-list="reviews.badgeList" />
            </div>
            <div class="column is-9">
              <div
                class="has-text-secondary is-size-5 has-text-weight-bold m-b">
                {{ reviews.summary.text }}
              </div>
              <div class="m-b-5x has-text-grey-light">
                <span
                  v-for="(it, idx) in reviews.summary.summarySentenceList"
                  :key="idx"
                  class="m-r">
                  "{{ it.text }}"
                </span>
              </div>
              <div>
                <TrustYouHotelType
                  v-for="it in reviews.hotelTypeList"
                  :key="it.categoryId"
                  v-bind="it"
                  text-type="shortText" />
              </div>
            </div>
          </div>
          <div v-if="reviews.goodToKnowList">
            <h4
              class="has-text-primary has-text-weight-bold is-size-5 m-b-5x">
              {{ $t('views.hotel.Good to know') }}
            </h4>
            <div class="columns is-multiline">
              <div
                v-for="(it, idx) in reviews.goodToKnowList"
                :key="idx"
                class="column is-3">
                <TrustYouGoodToKnow v-bind="it" />
              </div>
            </div>
          </div>
          <div v-if="reviews.categoryList">
            <h4
              class="has-text-primary has-text-weight-bold is-size-5 m-b-5x">
              {{ $t('views.hotel.Review highlight') }}
            </h4>
            <TrustYouHighLight
              v-for="it in reviews.categoryList"
              :key="it.categoryId"
              :category="it" />
          </div>
        </div>
        <!--Reviews end -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import HotelGallery from './HotelGallery'
import TrustYouRating from '@/components/TrustYouRating'
import StarRatings from '@/components/StarRatings'
import Bookmark from '@/components/Bookmark'
import PropertyAddress from '@/components/PropertyAddress'
import TrustYouBadgeWrapper from '@/components/TrustYouBadgeWrapper'
import TrustYouRanked from '@/components/TrustYouRanked'
import BadgeOutline from '@/components/BadgeOutline'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import TrustYouBadgeList from '@/components/TrustYouBadgeList'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouGoodToKnow from '@/components/TrustYouGoodToKnow'
import TrustYouHighLight from '@/components/TrustYouHighLight'
import PropertyFacilities from '@/components/PropertyFacilities'
import CovidSafetyInfo from '@/components/CovidSafetyInfo'

export default {
  name: 'PropertyInfo',
  components: {
    PropertyFacilities,
    TrustYouHighLight,
    TrustYouGoodToKnow,
    TrustYouHotelType,
    TrustYouBadgeList,
    TrustYouRecommendation,
    TrustYouPopularWith,
    BadgeOutline,
    TrustYouRanked,
    TrustYouBadgeWrapper,
    PropertyAddress,
    Bookmark,
    StarRatings,
    TrustYouRating,
    HotelGallery,
    CovidSafetyInfo
  },
  props: {
    params: Object,
    query: Object
  },
  computed: {
    ...mapState('searchOne', [
      'property',
      'availability',
      'reviews',
      'isSearching',
      'isSearchingMore',
      'fetchCompleted',
      'nights'
    ]),
    property () {
      return this.$store.state.searchOne.property || { name: '' }
    },
    reviews () {
      return this.$store.state.searchOne.reviews
    },
    isLoading () {
      return this.isSearching || !this.fetchCompleted
    },
    history () {
      return window.history
    }
  },
  created () {
    this.$store.dispatch('searchOne/details', this.params)
    this.$store.commit('searchMany/SET_SEARCH_MANY', {
      selected: this.params.propertyCode
    })
  },
  methods: {
    handleBookmark (val) {
      this.$store.dispatch('user/toggleBookmark', { flag: val, property: this.property })
    }
  }
}
</script>
